import React from "react";

import "lightgallery.js/dist/css/lightgallery.css";
import "./myLightGallery.css";
import { LightgalleryProvider } from "react-lightgallery";

const defaultPlugins = [
  "lg-autoplay.js",
  "lg-fullscreen.js",
  "lg-thumbnail.js",
  "lg-video.js",
  "lg-zoom.js",
];

const defaultLightgallerySettings = {
  mode: "lg-fade",
  cssEasing: "ease",
  speed: 600,
  height: "100%",
  width: "100%",
  addClass: "",
  startClass: "lg-start-zoom",
  backdropDuration: 150,
  hideBarsDelay: 6000, // Delay for hiding gallery controls in ms
  useLeft: false, // force lightgallery to use css left property instead of transform.
  closable: true,
  loop: true,
  escKey: true,
  keyPress: true,
  slideEndAnimation: true,
  controls: true, //  If false, prev/next buttons will not be displayed.
  hideControlOnEnd: true, // false If true, prev/next button will be hidden on first/last image.
  // index: 1,
  getCaptionFromTitleOrAlt: true,
  subHtmlSelectorRelative: false, // Set to true if the selector in "data-sub-html" should use the current item as its origin.
  appendSubHtmlTo: ".lg-sub-html",
  ariaLabelledby: "",
  ariaDescribedby: "",
  preload: 1,
  showAfterLoad: true, // Show Content once it is fully loaded
  selector: "", // Custom selector property instead of just child. pass this to select same element ex : .class #id

  selectWithin: "", // By default selectror element is taken from only inside the gallery element.
  nextHtml: "<div>ďalší</div>",
  prevHtml: "<div>predchádzajúci</div>",
  index: 0,
  iframeMaxWidth: "100%",
  download: true,
  counter: true,
  appendCounterTo: ".lg-toolbar",
  swipeThreshold: 50,
  enableDrag: true,
  enableTouch: true,
  dynamic: false /* LightGallery can be instantiated and launched programmatically by setting this option to true and 
populating dynamicEl option (see below) with the definitions of images.*/,
  dynamicEl: [], // An array of objects (src, iframe, subHtml, thumb, poster, responsive, srcset sizes) representing gallery elements.
};
const MyLightGalleryProvider = ({
  children,
  myLightgallerySettings = {},
  myPlugins = [],
  ...restProps
}) => {
  // lightgallerySettings={
  //   {
  // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
  //   }
  // }
  const lightgallerySettings = {
    ...defaultLightgallerySettings,
    ...myLightgallerySettings,
  };
  const plugins = [...defaultPlugins, ...myPlugins];
  return (
    <LightgalleryProvider
      lightgallerySettings={lightgallerySettings}
      plugins={plugins}
      {...restProps}
    >
      {children}
    </LightgalleryProvider>
  );
};

export default MyLightGalleryProvider;

/* <LightgalleryProvider
            onBeforeOpen={() => console.info("onBeforeOpen")}
            onAfterOpen={() => console.info("onAfterOpen")}
            onSlideItemLoad={() => console.info("onSlideItemLoad")}
            onBeforeSlide={() => console.info("onBeforeSlide")}
            onAfterSlide={() => console.info("onAfterSlide")}
            onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
            onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
            onDragstart={() => console.info("onDragstart")}
            onDragmove={() => console.info("onDragmove")}
            onDragend={() => console.info("onDragend")}
            onSlideClick={() => console.info("onSlideClick")}
            onBeforeClose={() => console.info("onBeforeClose")}
            onCloseAfter={() => console.info("onCloseAfter")} 
            galleryClassName="naMobile"
          ></LightgalleryProvider> */

/*
lightgallerySettings

Name	      type	default	        description

mode	      string	'lg-slide'	  Type of transition between images. lightGallery comes with lots of transition effects such as 
'lg-slide''lg-fade''lg-zoom-in''lg-zoom-in-big''lg-zoom-out''lg-zoom-out-big''lg-zoom-out-in''lg-zoom-in-out''lg-soft-zoom'
'lg-scale-up''lg-slide-circular''lg-slide-circular-vertical''lg-slide-vertical''lg-slide-vertical-growth''lg-slide-skew-only'
'lg-slide-skew-only-rev''lg-slide-skew-only-y''lg-slide-skew-only-y-rev''lg-slide-skew''lg-slide-skew-rev''lg-slide-skew-cross'
'lg-slide-skew-cross-rev''lg-slide-skew-ver''lg-slide-skew-ver-rev''lg-slide-skew-ver-cross''lg-slide-skew-ver-cross-rev''lg-lollipop'
'lg-lollipop-rev''lg-rotate''lg-rotate-rev''lg-tube'. You can find detaild demo here

cssEasing	          string	'ease'	      Type of easing to be used for css animations
speed	              number	600	          Transition duration (in ms).
height	            string	'100%'	      Height of the gallery. ex: '100%' , '300px'
width	              string	'100%'	      Width of the gallery. ex: '100%' , '300px'
addClass	          string	''	          Add custom class for gallery, can be used to set different style for different gallery
startClass	        string	'lg-start-zoom'	    Starting animation class for the gallery.
backdropDuration	  number	150	  Lightgallery backdrop transtion duration. Do not change the value of backdrop via css.
hideBarsDelay	      number	6000	Delay for hiding gallery controls in ms
useLeft	            boolean	false	        force lightgallery to use css left property instead of transform.
closable	          boolean	true	        allows clicks on dimmer to close gallery.
loop	              boolean	true	        If false, will disable the ability to loop back to the beginning of the gallery when on the last element.
escKey	            boolean	true	        Whether the LightGallery could be closed by pressing the "Esc" key.
keyPress	          boolean	true	        Enable keyboard navigation
controls	          boolean	true	        If false, prev/next buttons will not be displayed.
slideEndAnimatoin	  bolean	true	  Enable slideEnd animation
hideControlOnEnd	  boolean	false	  If true, prev/next button will be hidden on first/last image.
getCaptionFromTitleOrAlt:	  boolean	true	            Option to get captions from alt or title tags.
subHtmlSelectorRelative	    boolean	false	            Set to true if the selector in "data-sub-html" should use the current item as its origin.
appendSubHtmlTo	            string	'.lg-sub-html'	  You can specify where the sub-html should be appended. '.lg-sub-html' or '.lg-item'
ariaLabelledby	            string	''	              aria-labelledby attribute for gallery
ariaDescribedby	            string	''	              aria-describedby attribute for gallery

preload	     number	1	            Number of preload slides. will execute only after the current slide is fully loaded. 
ex:// you clicked on 4th image and if preload = 1 then 3rd slide and 5th slide will be loaded in the background after the 4th slide is fully loaded.. 
if preload is 2 then 2nd 3rd 5th 6th slides will be preloaded

showAfterLoad	      boolean	true	    Show Content once it is fully loaded
selector	   string	''	          Custom selector property instead of just child. pass this to select same element ex : .class #id

selectWithin	      string	''	  By default selectror element is taken from only inside the gallery element. 
Instead of that you can tell lightgallery to select element within a specific element. ex : .class #id
document.querySelector(this.s.selectWithin ).querySelectorAll(this.s.selector);

nextHtml	  string	''	Custom html for next control
prevHtml	  string	''	Custom html for prev control
index	      number	0	Allows to set which image/video should load initially
iframeMaxWidth	    string	'100%'	Set maximum width for iframe.

download	  boolean	true	        Enable download button. By default download url will be taken from data-src/href attribute but 
it supports only for modern browsers. If you want you can provide another url for download via data-download-url. 
Pass false in data-download-url if you want to hide download button for the particular slide.

counter	    boolean	true	        Whether to show total number of images and index number of currently displayed image.
appendCounterTo	    string	'.lg-toolbar'	    Where the counter should be appended
swipeThreshold	    number	50	  By setting the swipeThreshold (in px) you can set how far the user must swipe for the next/prev image.
enableDrag	        boolean	true	    Enables desktop mouse drag support
enableTouch	        boolean	true	    Enables touch support
dynamic	    boolean	false	        LightGallery can be instantiated and launched programmatically by setting this option to true and 
populating dynamicEl option (see below) with the definitions of images.

dynamicEl	  array	[]	          An array of objects (src, iframe, subHtml, thumb, poster, responsive, srcset sizes) representing gallery elements. 
See example.
          */
